import { bindable, inject }   from 'aurelia-framework';
import { I18N }               from 'aurelia-i18n';
import { BaseListViewModel }  from 'base-list-view-model';
import { FilterFormSchema }   from 'modules/administration/consents/filter-form-schema';
import { ConsentsRepository } from 'modules/administration/consents/services/repository';
import { AppContainer }       from 'resources/services/app-container';
import { Downloader }         from 'resources/services/downloader';

@inject(AppContainer, ConsentsRepository, I18N, Downloader, FilterFormSchema)
export class ListConsents extends BaseListViewModel {

    listingId = 'administration-consents-listing';

    @bindable headerTitle    = 'listing.administration.consents';
    @bindable newRecordRoute = 'administration.consents.create';
    @bindable repository;
    @bindable datatable;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param i18N
     * @param downloader
     * @param filterFormSchema
     */
    constructor(appContainer, repository, i18N, downloader, filterFormSchema) {
        super(appContainer);

        this.repository       = repository;
        this.i18N             = i18N;
        this.downloader       = downloader;
        this.filterFormSchema = filterFormSchema;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return this.appContainer.globalConfig.defaultListView;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'administration.consents.manage',
            'administration.consents.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate() {
        super.activate();

        this.defineDatatable();

        this.filterSchema = this.filterFormSchema.schema(this);
        this.filterModel  = this.filterFormSchema.model(this);
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:      this.repository,
            show:            {
                action:  (row) => this.appContainer.router.navigateToRoute('administration.consents.view', { id: row.id }),
                visible: (row) => this.appContainer.authenticatedUser.can(['administration.consents.manage', 'administration.consents.view']),
            },
            edit:            {
                action:  (row) => this.appContainer.router.navigateToRoute('administration.consents.edit', { id: row.id }),
                visible: (row) => this.appContainer.authenticatedUser.can(['administration.consents.manage', 'administration.consents.edit']),
            },
            destroy:         {
                action:  (row) => this.repository.destroy(row.id),
                visible: (row) => this.appContainer.authenticatedUser.can(['administration.consents.manage', 'administration.consents.delete']),
            },
            options:         [
                {
                    label:   'form.button.export-to-excel',
                    icon:    'icon-file-excel',
                    action:  () => this.exportListing(this.i18N.tr('form.field.consents'), !this.datatable.instance.selectedRows.length ? null : this.datatable.instance.selectedRows),
                    visible: (row) => this.appContainer.authenticatedUser.can(['administration.consents.manage', 'administration.consents.view']),
                },
            ],
            selectable:      true,
            destroySelected: true,
            sorting:         {
                column:    0,
                direction: 'asc',
            },
            columns:         [
                {
                    data:  'name',
                    name:  'consent_translations.name',
                    title: 'form.field.name',
                },
                {
                    data:  'onetrust_guid',
                    name:  'consents.onetrust_guid',
                    title: 'form.field.onetrust-guid',
                },
                {
                    data:  'status',
                    name:  'boolean_statuses.name',
                    title: 'form.field.status',
                    type:  'label',
                },
                {
                    data:  'created_by',
                    name:  'users.name',
                    title: 'form.field.created-by',
                },
                {
                    data:            'created_at',
                    name:            'sector.created_at',
                    title:           'form.field.created-at',
                    valueConverters: [
                        {
                            name: 'dateTimeFormat',
                        },
                    ],
                },
            ],
        };
    }

    /**
     * Make list of allowed users
     *
     * @param row
     * @returns {string}
     */
    joinAllowedUsers(row) {
        var html = '<ul>';

        for (var name of row['allowed_users']) {
            html += '<li>';
            html += name;
            html += '</li>';
        }

        html += '</ul>';
        return html;
    }

}
