import '@babel/polyfill';

import { AuthService }         from 'aurelia-auth';
import { PLATFORM }            from 'aurelia-pal';
import { AureliaAuthConfig }   from 'resources/configs/aurelia-auth-config';
import { AureliaDialogConfig } from 'resources/configs/aurelia-dialog-config';
import { AureliaI18NConfig }   from 'resources/configs/aurelia-i18n-config';
import environment             from '../config/environment.json';

import 'assets/css/icons/icomoon/styles.min.css';
import 'assets/css/icons/fontawesome/v4/styles.min.css';
import 'assets/css/icons/fontawesome/v6/all.min.css';
//import 'assets/css/bootstrap.min.css';
//import 'assets/css/bootstrap_limitless.min.css';
import 'assets/css/layout.min.css';
//import 'assets/css/components.min.css';
//import 'assets/css/colors.min.css';
import 'assets/css/material-design-spinner.min.css';
//import 'assets/css/custom.min.css';
import 'resources/support/utilities';

export function configure(aurelia) {
    aurelia.use
        .standardConfiguration()
        .developmentLogging(environment.debug ? 'debug' : 'warn')
        .plugin(PLATFORM.moduleName('jquery'))
        .plugin(PLATFORM.moduleName('jquery-ui-dist/jquery-ui.min'))
        .plugin(PLATFORM.moduleName('bootstrap'))
        .plugin(PLATFORM.moduleName('aurelia-animator-css'))
        .plugin(PLATFORM.moduleName('aurelia-auth'), config => config.configure(AureliaAuthConfig))
        .plugin(PLATFORM.moduleName('aurelia-i18n'), config => AureliaI18NConfig.configure(aurelia, config))
        .plugin(PLATFORM.moduleName('aurelia-dialog'), config => AureliaDialogConfig.configure(config))
        .plugin(PLATFORM.moduleName('aurelia-ui-virtualization'))
        .feature(PLATFORM.moduleName('resources/attributes/index'))
        .feature(PLATFORM.moduleName('resources/elements/aurelia-form/index'))
        .feature(PLATFORM.moduleName('resources/elements/form-elements/index'))
        .feature(PLATFORM.moduleName('resources/elements/html-elements/index'))
        .feature(PLATFORM.moduleName('resources/value-converters/index'))
        .preTask(registerAdditionalPlugins)
        .preTask(registerIntendedUrl);

    aurelia.use.developmentLogging(environment.debug ? 'debug' : 'warn');

    aurelia.start().then(() => aurelia.setRoot(applicableRoot(aurelia)));
}

/**
 * Registers additional plugins
 *
 * @param frameworkConfiguration
 *
 * @returns {*}
 */
function registerAdditionalPlugins(frameworkConfiguration) {
    if (environment.testing) {
        frameworkConfiguration.aurelia.use.plugin(PLATFORM.moduleName('aurelia-testing'));
    }

    return frameworkConfiguration;
}

/**
 * Registers intended url
 *
 * @param frameworkConfiguration
 *
 * @returns {*}
 */
function registerIntendedUrl(frameworkConfiguration) {
    let auth = frameworkConfiguration.container.get(AuthService);

    if (!auth.isAuthenticated()) {
        auth.auth.setInitialUrl(window.location.href);
    }

    return frameworkConfiguration;
}

/**
 * Returns applicable root
 *
 * @param frameworkConfiguration
 *
 * @returns {string}
 */
function applicableRoot(frameworkConfiguration) {
    if (environment.maintenance === true) {
        return PLATFORM.moduleName('maintenance');
    }

    let auth = frameworkConfiguration.container.get(AuthService);

    if (!auth.isAuthenticated()) {
        return PLATFORM.moduleName('app-offline');
    }

    return auth.getTokenPayload().requires_consents === true
        ? PLATFORM.moduleName('consents')
        : PLATFORM.moduleName('app');
}

