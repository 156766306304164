import { inject }          from 'aurelia-framework';
import { BooleanStatus }   from 'modules/administration/models/boolean-status';
import { Consent }         from 'modules/administration/models/consent';
import { UsersRepository } from 'modules/administration/users/services/repository';

@inject(UsersRepository)
export class FormSchema {

    /**
     * Model default values
     */
    modelDefaults = {
        status_id: BooleanStatus.ACTIVE,
    };

    /**
     * Constructor
     *
     * @param usersRepository
     */
    constructor(usersRepository) {
        this.usersRepository = usersRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {Consent}
     */
    model() {
        let model = new Consent();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @param readonly
     * @returns {*[]}
     */
    schema(viewModel, readonly = false) {
        this.name = {
            type:       'text',
            key:        'name',
            label:      'form.field.designation',
            size:       8,
            attributes: {
                maxlength: 255,
                readonly:  readonly,
            },
        };

        this.onetrust_guid = {
            type:       'text',
            key:        'onetrust_guid',
            label:      'form.field.onetrust-guid',
            size:       4,
            attributes: {
                maxlength: 255,
                readonly:  readonly,
            },
        };

        this.description = {
            type:       'textarea',
            key:        'description',
            label:      'form.field.description',
            size:       12,
            attributes: {
                readonly: readonly,
            },
        };

        this.status_id = {
            type:       'boolean-statuses-select',
            key:        'status_id',
            label:      'form.field.status',
            size:       4,
            attributes: {
                disabled: readonly,
            },
        };

        const schema = [
            [this.name, this.onetrust_guid],
            [this.description],
            [this.status_id],
        ];

        if (readonly) {
            this.created_by = {
                type:       'text',
                key:        'created_by',
                label:      'form.field.created-by',
                size:       6,
                required:   false,
                attributes: {
                    readonly: true,
                },
            };

            this.created_at = {
                type:       'text',
                key:        'created_at',
                label:      'form.field.created-at',
                size:       6,
                required:   false,
                attributes: {
                    readonly: true,
                },
            };

            return [...schema, [this.created_by, this.created_at]];
        }

        return schema;
    }

    /**
     * Returns buttons schema
     *
     * @param viewModel
     * @param readonly
     *
     * @returns {*[]}
     */
    globalSchema(viewModel, readonly = false) {
        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            action:     () => viewModel.redirectToRoute('administration.consents.index'),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.clearButton = {
            type:       'button',
            label:      viewModel.create ? 'form.button.clear' : 'form.button.reverse-changes',
            action:     () => viewModel.resetForm(),
            hidden:     readonly,
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.submit(),
            hidden:     readonly,
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
                this.clearButton,
                this.submitButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

}
