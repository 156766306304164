import { bindable, bindingMode, customElement, inject } from 'aurelia-framework';
import { BaseViewModel }                                from 'base-view-model';
import { FormSchema }                                   from 'modules/administration/files/form-schema';
import { AppContainer }                                 from 'resources/services/app-container';
import { Downloader }                                   from 'resources/services/downloader';

@customElement('list-files')
@inject(AppContainer, Downloader, FormSchema)
export class ListFiles extends BaseViewModel {

    listingId = 'files-listing-' + Math.floor(Math.random() * 65000);
    formId    = 'files-creation-form-' + Math.floor(Math.random() * 65000);

    @bindable({ defaultBindingMode: bindingMode.twoWay }) readonly = false;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) relatableModel;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) relatableRepository;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) allowedMimeTypes;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) hideName;

    datatable;
    creationSchemaVisible = false;
    hasFileNotSubmited    = false;

    /**
     * Constructor
     *
     * @param appContainer
     * @param downloader
     * @param formSchema
     */
    constructor(appContainer, downloader, formSchema) {
        super(appContainer);

        this.downloader = downloader;
        this.formSchema = formSchema;
    }

    /**
     * Handles activate event
     */
    bind() {
        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();
        this.schema       = this.formSchema.schema(this);

        this.defineDatatable();
    }

    /**
     * Defines datatable
     */
    defineDatatable() {
        this.datatable = {
            resource:        'resource.administration.file',
            repository:      {
                search:          (criteria) => this.relatableRepository.search(this.relatableModel.id, criteria),
                destroy:         (id) => this.relatableRepository.destroy(id),
                destroySelected: (ids) => this.relatableRepository.destroySelected(ids),
            },
            destroy:         !this.readonly,
            destroySelected: !this.readonly,
            actions:         [
                {
                    icon:    'icon-download7',
                    action:  (row) => this.downloadFile(row),
                    tooltip: 'form.button.export',
                },
            ],
            options:         [],
            buttons:         [
                {
                    label:     'form.button.create-new',
                    icon:      'icon-plus3',
                    className: 'btn bg-success',
                    color:     'success',
                    visible:   !this.readonly,
                    action:    () => this.creationSchemaVisible = true,
                },
            ],
            sorting:         {
                column:    0,
                direction: 'asc',
            },
            selectable:      !this.readonly,
            columns:         [
                {
                    data:   'display_name',
                    name:   'files.display_name',
                    title:  'form.field.name',
                    hidden: this.hideName,
                },
                {
                    data:  'description',
                    name:  'files.description',
                    title: 'form.field.description',
                },
                {
                    data:            'created_at',
                    name:            'files.created_at',
                    title:           'form.field.created-at',
                    valueConverters: [
                        {
                            name: 'dateTimeFormat',
                        },
                    ],
                },
                {
                    data:  'creator',
                    name:  'creators.name',
                    title: 'form.field.created-by',
                },
            ],
        };
    }

    /**
     * Submits view form
     */
    submit() {
        this.alert = null;

        // calls repository create method
        return this.relatableRepository.create(this.relatableModel.id, this.model)
            .then((response) => {
                this.alert = this.alertMessage(response.status, response.message, response.errors);

                if (response.status === true) {
                    this.datatable.instance.reload();
                    this.resetForm();
                }
            }).catch(console.error.bind(console));
    }

    /**
     * Downloads file
     *
     * @param row
     */
    downloadFile(row) {
        this.relatableRepository.download(row.id)
            .then(result => this.downloader.download(result, row.display_name, row.extension));
    }

    /**
     * Resets form fields
     */
    resetForm(nullifyAlert = true) {
        this.resetModelValues()
            .then(() => {
                // publishes `form-reseted` event
                this.appContainer.eventAggregator.publish('form-reseted', this.formId);

                if (nullifyAlert) {
                    this.alert = null;
                }
            });
    }

    /**
     * Resets model to initial values
     *
     * @returns {Promise}
     */
    resetModelValues() {
        return new Promise((resolve, reject) => {
                this.model.assign(this.initialModel);
                resolve(true);
                reject(new Error('Error'));
            },
        );
    }

}
